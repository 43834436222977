<template>
	<v-container fluid tag="section">
		<v-form ref="form" v-model="valid" lazy-validation>
		<ti-card icon="mdi-file-document-outline">
			<template #title><span>{{ isEdit ? 'Edit' : 'New' }}</span> Brand</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small class="mr-4" @click="save">
					<v-icon>{{ $icons.save }}</v-icon>
				</v-btn>
			</template>
			<template #title-actions-hidden>
				<ti-confirm @confirm="destroy" message="Are you sure you want to delete this brand?" button-text="Delete">
					<v-btn color="error" elevation="2" fab small class="mr-4">
						<v-icon>{{ $icons.delete }}</v-icon>
					</v-btn>
				</ti-confirm>
			</template>
			<ti-form-layout>
				<template #sidebar>
					<v-col cols="12">
						<ti-image-upload v-model="brand.image_id" label="Logo" :image-id="brand.image_id" :key="brand.id"></ti-image-upload>
					</v-col>
				</template>
				<template>
					<v-col cols="12">
						<v-text-field v-model="brand.name" :rules="validations.name" label="Name"></v-text-field>
					</v-col>
				</template>
			</ti-form-layout>
		</ti-card>
	</v-form>
	</v-container>
</template>
<script>

import validationRules from "@/util/validation";
import _forEach from 'lodash/forEach'
import {sync} from "vuex-pathify";

export default {

	name: "BrandsEdit",
	props: ['id'],
	computed: {
		isEdit() {
			return this.$route.meta?.edit
		},
		brand: sync('brands/brand')
	},
	data: () => ({
		valid: false,
		validations: {
			name: validationRules('Name', ['required', 'minLength:2']),
		}
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			if (this.isEdit) {
				this.$store.set('brands/getBrand!', this.id)
			} else {
				this.brand = {
					name: ''
				}
			}
		},
		getBrand() {
			this.$api.brands.show(this.id)
				.then(response => {
					this.brand = response.data
				})
				.catch(error => {
					console.log(error.response);
					this.$toast.add(error.response.message, 'error')
					// this.$toast.handleResponseErrors(this, error)
				})
		},
		save() {
			if (this.$refs.form.validate()) {
				if (this.isEdit) {
					this.$api.brands.update(this.id, this.brand)
						.then(response => {
							this.$toast.add('Brand updated successfully', 'success')
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Brands'})
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error');
						})
				} else {
					this.$api.brands.create(this.brand)
						.then(response => {
							this.$toast.add('Brand created successfully', 'success');
							this.$route.meta.disableWarn = true;
							this.$router.push({name: 'Brands'});
						})
						.catch(error => {
							console.log(error.response);
							this.$toast.add(error.response.statusText, 'error')
						})
				}

			} else {
				this.$toast.add('Please fix validation errors', 'error')
			}
		},
		destroy() {
			this.$api.brands.delete(this.id, this.track)
				.then(response => {
					this.$toast.add('Brand deleted successfully', 'success')
					this.$route.meta.disableWarn = true;
					this.$router.push({name: 'Brands'})
				})
				.catch(error => {
					console.log(error.response);
					this.$toast.add(error.response.statusText, 'error')
				})
		},
	}
}
</script>